import React from 'react'
import Layout from '../components/Layout'

const Definition = ({ location }) => {
  return (
    <Layout location={location} lang={'en'}>
      <section id="one">
        <header className="major">
          <h2>Musical stimulation in nursery schools</h2>
        </header>
        <p>
          The <strong>musical stimulation sessions and workshops</strong> for nurseries are designed to stimulate children's development through sound play, songs, movement, and musical expression. Music is a powerful tool to promote emotional well-being, bonding, language, and learning in early childhood.
        </p>
        <p>
          These workshops are also aimed at the children's educators, who will be present during the sessions. This way, they will be able to acquire musical resources that they can use in their daily routines, promoting continuity in musical stimulation. Furthermore, it will help strengthen the teacher-child bond, creating a deeper connection and a better learning experience.
        </p>
        <h2>What do we propose?</h2>
        <p>
          <ul>
            <li><strong>Experiential and active sessions</strong> tailored to each age group.</li>
            <li><strong>Sound and rhythmic exploration</strong> using instruments, materials, voice, and body.</li>
            <li><strong>Songs and musical games</strong> to stimulate language and motor skills.</li>
            <li><strong>Movement and body expression</strong> to naturally integrate music through the body, fostering coordination and creativity.</li>
            <li><strong>Moments of relaxation and connection</strong> to support emotional self-regulation.</li>
          </ul>
        </p>
        <p>
          The sessions can be organized <strong>weekly</strong>, <strong>biweekly</strong>, or as <strong>one-off workshops</strong>, depending on the needs of each nursery. Each session is designed with a <strong>respectful and experiential</strong> approach, adapting to the needs of each group and school.
        </p>
        <p>
          Contact me to learn more details and create a unique musical experience for the little ones!
        </p>
      </section>
      <section id="three">
        <h2>Upcoming Events</h2>
        <p>
          To check the upcoming music stimulation workshops,
          <a
            className="button special small"
            style={{ marginLeft: '15px' }}
            href='/events'
          >
            click here!
          </a>
        </p>
      </section>
    </Layout>
  )
};

export default Definition;
